<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" md="5">
        <v-toolbar class="mb-2" v-if="estadoEdit.idEstado">
          <v-btn icon @click.stop="estadoEdit = {}">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          Editando
          <status-chip class="ml-2" :value="estadoEdit.estado" />
        </v-toolbar>
        <EditarEstados :estado="estadoEdit" :key="estadoEdit.idEstado" @reload="getEstados(); estadoEdit = Object.create(Object.prototype)" />
      </v-col>
      <v-col cols="12" md="7">
        <v-subheader>Estados generales</v-subheader>
        <v-card class="d-flex flex-wrap pa-2 align-start" style="gap: 15px">
          <!-- <div class="d-flex mb-2 align-center" :key="estado.idEstado" v-for="estado in estados">
            <div class="flex mr-2">
              <status-chip :value="estado.estado" />
            </div>
            <span class="text-caption">{{ estado.descripcion }}</span>
          </div> -->
          <v-alert
            max-width="500"
            style="width: 100%"
            class="ma-0"
            text
            prominent
            dense
            :key="estado.idEstado"
            v-for="estado in estadosGen"
            :color="getSituacionIcon(estado.estado).color"
            :icon="getSituacionIcon(estado.estado).icon"
          >
            <div class="d-flex">
              <div v-text="estado.estado" class="text-h6" />
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                rounded
                :color="getSituacionIcon(estado.estado).color"
                @click.stop="estadoEdit = estado"
              >
                <v-icon left>mdi-pencil</v-icon>
                <!-- <v-icon v-else left>mdi-lock</v-icon> -->
                Editar
              </v-btn>
            </div>
            <v-sheet
              width="100%"
              height="2px"
              class="mb-2"
              :color="getSituacionIcon(estado.estado).color"
            ></v-sheet>
            <div>{{ estado.descripcion }}</div>
          </v-alert>
        </v-card>
        <v-subheader>Estados internos</v-subheader>
        <v-card class="d-flex flex-wrap pa-2 align-start" style="gap: 15px">
          <v-alert
            max-width="500"
            style="width: 100%"
            class="ma-0"
            text
            prominent
            dense
            :key="estado.idEstado"
            v-for="estado in estadosInt"
            :color="getSituacionIcon(estado.estado).color"
            :icon="getSituacionIcon(estado.estado).icon"
          >
            <div class="d-flex">
              <div v-text="estado.estado" class="text-h6" />
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                rounded
                :disabled="Boolean(estado.locked)"
                :color="getSituacionIcon(estado.estado).color"
                @click.stop="estadoEdit = estado"
              >
                <v-icon v-if="!estado.locked" left>mdi-pencil</v-icon>
                <v-icon v-else left>mdi-lock</v-icon>
                Editar
              </v-btn>
            </div>
            <v-sheet
              width="100%"
              height="2px"
              class="mb-2"
              :color="getSituacionIcon(estado.estado).color"
            ></v-sheet>
            <div>{{ estado.descripcion }}</div>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    EditarEstados: () => import("@/components/EditarEstados.vue"),
    Editable: () => import("@/components/Editable.vue"),
  },
  data() {
    return {
      nuevoEstado: {
        icon: null,
        color: null,
        text: null,
      },
      estadoEdit: {},
      estadosGen: [],
      estadosInt: [],
    };
  },
  methods: {
    getSituacionIcon,
    async getEstados() {
      const { data: gen } = await axios(`${process.env.VUE_APP_API_URL}/opciones/estados.php?interno=0`)
      const { data: int } = await axios(`${process.env.VUE_APP_API_URL}/opciones/estados.php?interno=1`)
      // this.estados = [...gen, ...int]
      this.estadosGen = gen;
      this.estadosInt = int
    },
  },
  mounted() {
    this.getEstados();
  },
};
</script>

<style></style>
